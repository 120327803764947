/* eslint-disable no-magic-numbers */
import { makeStyles } from '@material-ui/styles'

type Props = {
  isBold: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({ isBold }: Props) => {
  const commonListItemStyles = {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '12px',
  }

  const listItemStyles = {
    height: 40,
  }

  const iconStyles = {
    color: '#9e9e9e',
    height: '20px !important',
    width: '20px !important',
    marginLeft: 6,
  }

  return makeStyles({
    selectedListItemTextStyles: {
      fontWeight: isBold ? 600 : 400,
      color: 'rgb(39, 143, 182)',
      ...commonListItemStyles,
    },
    unselectedListItemTextStyles: {
      fontWeight: isBold ? 600 : 400,
      color: '#212121',
      ...commonListItemStyles,
    },
    listSubMenuItemTextStyles: {
      color: 'rgba(0, 0, 0, 0.87)',
      ...commonListItemStyles,
      fontSize: '12px',
    },
    listItemStyles,
    subMenuListItemStyles: {
      ...listItemStyles,
      gutters: {
        paddingRight: 8,
      },
    },
    leftListItemIconStyles: {
      minWidth: 32,
      margin: 0,
    },
    rightListItemIconStyles: {
      minWidth: 24,
    },
    iconSelected: {
      color: '#278FB6 !important',
    },
    iconStyles,
  })
}
