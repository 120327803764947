type Props = {
  className?: string
}

const OssTiltedLogo = (props: Props): JSX.Element => (
  <svg
    fill="none"
    height="89"
    viewBox="0 0 89 89"
    width="89"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_2577_13844)">
      <rect
        fill="white"
        height="47.7137"
        rx="9.17571"
        transform="rotate(-14.4961 15.6913 24.2947)"
        width="47.7137"
        x="15.6913"
        y="24.2947"
      />
      <path
        clipRule="evenodd"
        d="M41.103 45.8538L43.5222 55.5433L50.7532 48.6022L48.334 38.9127L41.103 45.8538Z"
        fill="#288FB6"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M49.3565 38.0466L51.7132 47.4944L58.6129 40.768L56.2562 31.3202L49.3565 38.0466Z"
        fill="#288FB6"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M52.1375 48.8032L54.5345 58.2405L61.4342 51.5141L59.0775 42.0663L52.1375 48.8032Z"
        fill="#288FB6"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M28.6231 31.3299L37.9705 34.0707L44.9196 27.2025L35.5722 24.4618L28.6231 31.3299Z"
        fill="#69B1CC"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M38.843 34.619L48.3515 37.3181L55.502 30.3979L45.9934 27.6988L38.843 34.619Z"
        fill="#69B1CC"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M30.8012 42.4142L40.3098 45.1133L47.4602 38.193L37.9517 35.4939L30.8012 42.4142Z"
        fill="#69B1CC"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="92.1382"
        id="filter0_d_2577_13844"
        width="92.1381"
        x="-1.30872"
        y="-1.64868"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="8.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_2577_13844"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2577_13844"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default OssTiltedLogo
