import { useState } from 'react'
import type { ComponentPropsWithRef } from 'react'
import { UnconnectedBaseNavMenu } from 'common/components/navmenu/BaseNavMenu'

type UnconnectedBaseNavMenuProps = ComponentPropsWithRef<
  typeof UnconnectedBaseNavMenu
>
type Props = Omit<UnconnectedBaseNavMenuProps, 'isOpen' | 'onClose' | 'onOpen'>

const OpenableNavMenu = (props: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const baseNavMenuProps = {
    isOpen,
    onClose,
    onOpen,
    ...props,
  }

  return <UnconnectedBaseNavMenu {...baseNavMenuProps} />
}

export default OpenableNavMenu
