import type { ReactElement } from 'react'
import { List } from '@material-ui/core'
import ProductConstants from 'common/constants/products'
import ProductMenuItems from 'common/components/navmenu/ProductMenuItems'

type ProductName = string
type Path = string
type Props = {
  MobileAppDialog: ReactElement
  productLinks: Record<ProductName, Path>
  selectedProduct?: ProductName
}

const HelpAndFeedback = ({
  MobileAppDialog,
  productLinks,
  selectedProduct,
}: Props): JSX.Element => {
  const helpPath = productLinks[ProductConstants.HELP]
  const feedbackEmail = productLinks[ProductConstants.SEND_FEEDBACK]
  const workplacePortalPath = productLinks[ProductConstants.WORKPLACE_PORTAL]
  const submitFeatureIdeaPath =
    productLinks[ProductConstants.SUBMIT_FEATURE_IDEA]
  const isNotVisualDirectory =
    selectedProduct !== ProductConstants.VISUAL_DIRECTORY
  const showSubmitFeatureIdeaItem =
    selectedProduct && isNotVisualDirectory && submitFeatureIdeaPath

  return (
    <List>
      {MobileAppDialog}
      {/* @ts-expect-error auto-src: non-strict-conversion */}
      {ProductMenuItems({})[ProductConstants.SEND_FEEDBACK]({
        email: feedbackEmail,
      })}
      {showSubmitFeatureIdeaItem &&
        /* @ts-expect-error auto-src: non-strict-conversion */
        ProductMenuItems({})[ProductConstants.SUBMIT_FEATURE_IDEA]({
          props: {
            href: submitFeatureIdeaPath,
            target: '_blank',
          },
        })}
      {helpPath &&
        /* @ts-expect-error auto-src: non-strict-conversion */
        ProductMenuItems({})[ProductConstants.HELP]({
          href: helpPath,
          target: '_blank',
        })}
      {workplacePortalPath &&
        /* @ts-expect-error auto-src: non-strict-conversion */
        ProductMenuItems({})[ProductConstants.WORKPLACE_PORTAL]({
          href: workplacePortalPath,
        })}
    </List>
  )
}

export default HelpAndFeedback
