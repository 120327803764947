import type { Map, RecordOf } from 'immutable'
import { makeStyles } from '@material-ui/styles'

import type { TUser } from 'common/records/user'
import OssFooterLogo from 'common/icons/ossFooterLogo'

import { FooterHamburgerMenuStyle } from '../styles/navmenu'

type Props = {
  version?: string | null
  customIOSAppLink?: string | null | undefined
  isIOSAppEnabled?: boolean | null
  currentUser?: Map<string, boolean | string> | RecordOf<TUser>
}

const useHamburgerMenuStyles = makeStyles(FooterHamburgerMenuStyle)

export default function AppMenuFooter({ version }: Props): JSX.Element {
  const hamburgerMenuClasses = useHamburgerMenuStyles()

  return (
    <div className={hamburgerMenuClasses.container}>
      <div className={hamburgerMenuClasses.content}>
        <OssFooterLogo className={hamburgerMenuClasses.logo} />
        <span className={hamburgerMenuClasses.version}>{version}</span>
      </div>
    </div>
  )
}
