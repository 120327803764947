const Products: Record<string, string> = {
  VISUAL_DIRECTORY: 'Visual Directory',
  MOVE_MANAGER: 'Move Manager',
  MOVE_MANAGER_BETA: 'Move Manager ',
  PLANNING_PLATFORM: 'Planning Platform',
  MOVES: 'Moves',
  SCENARIOS: 'Scenarios',
  GROWTH_FORECAST: 'Growth Forecast',
  STACK_PLANS: 'Stack Plans',
  PREFERENCES: 'Preferences',
  REPORTS: 'Reports',
  REPORTS_BETA: 'Reports ',
  INSIGHTS_HUB: 'Insights Hub',
  FACILITIES: 'Facilities',
  ADMIN: 'Admin',
  REQUEST_MANAGER: 'Request Manager',
  BOOKING_MANAGER: 'Booking Manager',
  ROOM_BOOKING_OVERVIEW: 'Room Booking Overview',
  SEND_FEEDBACK: 'Send Feedback',
  SUBMIT_FEATURE_IDEA: 'Submit feature idea',
  HELP: 'Help',
  MANAGE_ACCOUNT: 'Manage Account',
  SAFEGUARD: 'Safeguard',
  WORKPLACE_PORTAL: 'Workplace Portal',
  VISITOR_MANAGEMENT: 'Visitor Management',
}

export const mainProducts = [
  Products.VISUAL_DIRECTORY,
  Products.PLANNING_PLATFORM,
  Products.MOVE_MANAGER_BETA,
  Products.MOVES,
  Products.SCENARIOS,
  Products.GROWTH_FORECAST,
  Products.STACK_PLANS,
  Products.INSIGHTS_HUB,
  Products.REPORTS_BETA,
  Products.FACILITIES,
  Products.REQUEST_MANAGER,
  Products.BOOKING_MANAGER,
  Products.VISITOR_MANAGEMENT,
  Products.SAFEGUARD,
  Products.PREFERENCES,
  Products.ADMIN,
]
export default Products
