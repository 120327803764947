import Skeleton from '@material-ui/lab/Skeleton'
import { ListItem, List } from '@material-ui/core'

const BaseMenuLoadingState = (): JSX.Element => {
  const numberOfItems = 16

  const menuItems = Array(numberOfItems)
    .fill({})
    .map((_, index) => (
      <ListItem key={index} style={{ paddingLeft: 20 }}>
        <Skeleton
          animation="wave"
          height={25}
          style={{ borderRadius: '8px', transform: 'none', marginRight: '5px' }}
          variant="text"
          width="24px"
        />
        <Skeleton
          animation="wave"
          height={25}
          style={{ borderRadius: '8px', transform: 'none' }}
          variant="text"
          width="180px"
        />
      </ListItem>
    ))

  return <List>{menuItems}</List>
}

export default BaseMenuLoadingState
