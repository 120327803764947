import { useAdminConsoleRoomDisplaysConfiguredQuery } from 'generated/graphql'

type Result = {
  configured: boolean
  loading: boolean
}

const useRoomScreensConfiguration = (): Result => {
  const { data, loading } = useAdminConsoleRoomDisplaysConfiguredQuery()
  const configured = data?.roomDisplaysConfiguration.configured || false

  return {
    configured,
    loading,
  }
}

export default useRoomScreensConfiguration
