// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon'

const LightbulbIcon = (props: any) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_10715_1220)">
      <path
        d="M7.49984 17.4998C7.49984 17.9165 7.83317 18.3332 8.33317 18.3332H11.6665C12.1665 18.3332 12.4998 17.9165 12.4998 17.4998V16.6665H7.49984V17.4998ZM9.99984 1.6665C6.74984 1.6665 4.1665 4.24984 4.1665 7.49984C4.1665 9.49984 5.1665 11.2498 6.6665 12.2498V14.1665C6.6665 14.5832 6.99984 14.9998 7.49984 14.9998H12.4998C12.9998 14.9998 13.3332 14.5832 13.3332 14.1665V12.2498C14.8332 11.1665 15.8332 9.4165 15.8332 7.49984C15.8332 4.24984 13.2498 1.6665 9.99984 1.6665Z"
        fill="#9E9E9E"
      />
    </g>
    <defs>
      <clipPath id="clip0_10715_1220">
        <rect fill="white" height="20" width="20" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default LightbulbIcon
