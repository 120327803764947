import ActionTypes from '../constants/actionTypes'

export const close = () =>
  ({
    type: ActionTypes.NavMenu.CLOSE_REQUESTED,
  }) as const

export const open = () =>
  ({
    type: ActionTypes.NavMenu.OPEN_REQUESTED,
  }) as const
