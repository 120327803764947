import type defaultFlags from 'common/launchDarkly/default_flags'
import { isProductionEnv } from './utils'

const checkDevelopmentHostname = () =>
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost' ||
  window.location.hostname.endsWith('ossd.co')

type User = {
  key: string
  kind: string
  anonymous: boolean
  hostname: string
  pendoVisitorReports: Array<string>
}

declare global {
  interface OSS {
    launchDarklyUser?: User
  }

  interface Window {
    oss: OSS
  }
}

const launchDarklyContext = () => {
  const frontendHostname = isProductionEnv()
    ? window.location.hostname
    : 'localhost'
  const fallbackUser = {
    anonymous: true,
    hostname: undefined,
    key: frontendHostname,
    kind: 'user',
    pendoVisitorReports: undefined,
  }
  const userFromBackendConfig = window.oss?.launchDarklyUser || fallbackUser

  return {
    ...userFromBackendConfig,
    key: frontendHostname,
    hostname: frontendHostname,
    frontend: true,
  }
}

type ConfigOptions<F> = {
  flags?: F
}

const config = <F extends Partial<typeof defaultFlags>>({
  flags,
}: ConfigOptions<F> = {}) =>
  ({
    clientSideID: (() => {
      if (
        Object.keys(window?.oss?.mockedFlags || {}).length > 0 ||
        !isProductionEnv()
      ) {
        return ''
      }

      if (isProductionEnv() && !checkDevelopmentHostname()) {
        return '608027c7bcf4820be9b164d0'
      }

      return '608027c7bcf4820be9b164cf'
    })(),
    context: launchDarklyContext(),
    flags,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  }) as const

export default config
