import { useDispatch } from 'react-redux'
import type { Dispatch } from 'redux'

import type { Action } from 'common/reducers/navMenu'

type AppDispatch = Dispatch<Action>

const useAppDispatch: () => AppDispatch = useDispatch

export default useAppDispatch
