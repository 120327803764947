import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import SmartPhoneIcon from 'common/icons/SmartPhoneIcon'

import OssTiltedLogo from 'common/icons/OssTiltedLogo'
import QRCode from './QRCode'
import {
  useActionsStyles,
  useDialogStyles,
  useTitleStyles,
  useOuterContainerStyles,
  useMobileContainerStyles,
  useInstructionStyles,
  useDownloadInstructionStyles,
} from './styles'
import { IOS_APP_LINK_ITUNES, ANDROID_APP_LINK_GOOGLE_PLAY } from './constants'
import GooglePlayBadge from './badges/google_play_badge.png'
import AppStoreBadge from './badges/apple_app_store_badge.svg'

const MobileAppDialog = ({
  customIOSAppLink,
  isIOSAppEnabled,
  isNavMenuItemHidden,
  isUserAnonymous,
  isOpen,
  setIsOpen,
}: {
  customIOSAppLink: string | null | undefined
  isIOSAppEnabled?: boolean | null
  isNavMenuItemHidden?: boolean
  isUserAnonymous: boolean | null | undefined
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}): JSX.Element => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common',
  })

  const dialogClasses = useDialogStyles()
  const actionsClasses = useActionsStyles()
  const titleClasses = useTitleStyles()
  const outerContainerClasses = useOuterContainerStyles()
  const mobileContainerClasses = useMobileContainerStyles()
  const instructionClasses = useInstructionStyles()
  const downloadInstructionClasses = useDownloadInstructionStyles()

  const handleIOSAppClick = () => setIsOpen(true)

  const handleIOSAppModalClose = () => setIsOpen(false)

  const isAppMobileVisible =
    isIOSAppEnabled && !isUserAnonymous && !isNavMenuItemHidden

  return (
    <>
      {isAppMobileVisible && (
        <NavMenuItem
          component="a"
          isBold
          LeftIcon={SmartPhoneIcon}
          onClick={handleIOSAppClick}
        >
          {t('home_menu.mobile_app')}
        </NavMenuItem>
      )}

      <Dialog
        classes={dialogClasses}
        maxWidth="md"
        onClose={handleIOSAppModalClose}
        open={isOpen}
      >
        <DialogTitle classes={titleClasses}>
          {t('home_menu.mobile_app_dialog.title')}
        </DialogTitle>
        <DialogContent classes={outerContainerClasses}>
          <div className={mobileContainerClasses.outerContainer}>
            <div className={mobileContainerClasses.blueContainer}>
              <OssTiltedLogo />
              <div>
                <DialogContentText classes={downloadInstructionClasses}>
                  {t('home_menu.mobile_app_dialog.instructions.first_step')}
                </DialogContentText>
                <a
                  href={ANDROID_APP_LINK_GOOGLE_PLAY}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    alt="Get it on Google Play"
                    className={mobileContainerClasses.badges}
                    src={GooglePlayBadge}
                  />
                </a>
                <a
                  href={customIOSAppLink || IOS_APP_LINK_ITUNES}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    alt="Download on the App Store"
                    className={mobileContainerClasses.badges}
                    src={AppStoreBadge}
                  />
                </a>
              </div>
            </div>
            <DialogContentText classes={instructionClasses}>
              {t('home_menu.mobile_app_dialog.instructions.second_step')}
              <span>
                {' '}
                {t(
                  'home_menu.mobile_app_dialog.instructions.second_step_bolded',
                )}
              </span>
            </DialogContentText>
            <DialogContentText classes={instructionClasses}>
              {t('home_menu.mobile_app_dialog.instructions.third_step')}
            </DialogContentText>
          </div>
          <div className={useMobileContainerStyles().qrCode}>
            <QRCode />
          </div>
        </DialogContent>
        <DialogActions classes={actionsClasses}>
          <Button onClick={handleIOSAppModalClose}>
            <span className={mobileContainerClasses.boldedText}>
              {t('actions.cancel')}
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MobileAppDialog
