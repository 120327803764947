import type { ReactNode } from 'react'
import type { Link } from '../types'
import NavMenuItem from '../../NavMenuItem'
import MenuItemTitle from '../../MenuItemTitle'

type Props = {
  Icon: ReactNode
  product: string
  link: Link
  isProductSelected: boolean
  isProductRegistered: boolean
}

const SimpleProductItem = ({
  Icon,
  product,
  link: { href, target },
  isProductSelected,
  isProductRegistered,
}: Props): JSX.Element => (
  <NavMenuItem
    key={product}
    component="a"
    data-testid="nav-menu-simple-product-item"
    href={href}
    isSelected={isProductSelected}
    LeftIcon={Icon}
    target={target}
  >
    {/* @ts-expect-error auto-src: non-strict-conversion */}
    <MenuItemTitle product={product} registered={isProductRegistered} />
  </NavMenuItem>
)

export default SimpleProductItem
