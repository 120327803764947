import products from 'common/constants/products'

const MIN_FLASH_WIDTH = 1024
const MIN_FLASH_HEIGHT = 768

const isSmallerThanFlashMinimums = (): boolean =>
  window.innerWidth < MIN_FLASH_WIDTH || window.innerHeight < MIN_FLASH_HEIGHT

export default {
  [products.MOVE_MANAGER]: isSmallerThanFlashMinimums,
  [products.MOVES]: isSmallerThanFlashMinimums,
  [products.PREFERENCES]: isSmallerThanFlashMinimums,
  [products.REPORTS]: isSmallerThanFlashMinimums,
}
