// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { navbarIcon } from 'common/components/styles/navmenu'

type Props = {
  buttonClasses?: any
  iconClasses?: any
  htmlColor?: string
  Icon: any
  isDisabled?: boolean
  onClick: () => void
}
const useIconClasses = makeStyles({
  root: {
    width: 24,
    height: 24,
  },
})

const NavbarIconButton = ({
  htmlColor,
  Icon,
  isDisabled,
  onClick,
  iconClasses: customIconClasses,
  buttonClasses,
  ...rest
}: Props) => {
  const iconClasses = useIconClasses()

  return (
    <IconButton
      classes={buttonClasses}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      <Icon
        classes={customIconClasses || iconClasses}
        htmlColor={htmlColor || navbarIcon.iconStyle.color}
      />
    </IconButton>
  )
}

export default NavbarIconButton
