import type { FetchResult, NextLink, Operation } from '@apollo/client'
import { ApolloLink, Observable } from '@apollo/client'

export default class TimeoutLink extends ApolloLink {
  private readonly timeout: number

  constructor(timeout: number) {
    super()
    this.timeout = timeout
  }

  request(
    operation: Operation,
    forward: NextLink,
  ): Observable<FetchResult> | null {
    const { fetchOptions = {}, ...context } = operation.getContext()
    const controller = new AbortController()

    // Inject AbortController.signal to the `fetchOptions` via context
    operation.setContext({
      ...context,
      fetchOptions: { ...fetchOptions, signal: controller.signal },
    })

    // Return a new Observable that subscribes to the `forward`
    // and adds a timeout via window.setTimeout
    return new Observable<FetchResult>((observer) => {
      let timer: number

      // Discard timeout timer once received result or an error;
      // complete Observable once received result from different Links in chain
      const subscription = forward(operation).subscribe(
        (result) => {
          window.clearTimeout(timer)
          observer.next(result)
          observer.complete()
        },
        (error) => {
          window.clearTimeout(timer)
          observer.error(error)
          observer.complete()
        },
      )

      // Once timeout exceeded:
      // 1. Abort `fetch` request
      // 2. Discard other apollo links results
      // 3. Remove AbortController.signal from the context to avoid calling the
      //    obsolete controller on possible retry
      // 4. Put the error to the link chain
      timer = window.setTimeout(() => {
        controller.abort()
        subscription.unsubscribe()
        operation.setContext({
          ...context,
          fetchOptions: { ...fetchOptions, signal: null },
        })
        observer.error(
          new Error(`Apollo client timeout exceeded: ${this.timeout}ms`),
        )
      }, this.timeout)

      // Cleanup: discard timeout and links chain
      return () => {
        window.clearTimeout(timer)
        subscription.unsubscribe()
      }
    })
  }
}
