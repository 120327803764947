// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import useStyles from './styles'

export type Props = {
  children?: any
  component?: any
  email?: string
  href?: string
  isBold?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  isSubMenu?: boolean
  LeftIcon?: any
  onClick?: ((arg0: any) => void) | null | undefined
  RightIcon?: any
  target?: string
  to?: string
}

const getNavMenuId = (children: any): string => {
  if (Array.isArray(children)) {
    return children[0]
  }
  if (typeof children === 'object') {
    return children.props?.product
  }

  return children
}

const NavMenuItem = ({
  children,
  component,
  email,
  href,
  isBold = true,
  isDisabled,
  isSelected,
  isSubMenu,
  LeftIcon,
  onClick,
  RightIcon,
  target,
  to,
}: Props) => {
  const classes = useStyles({ isBold })()

  const menuItemClasses = isSubMenu
    ? classes.subMenuListItemStyles
    : classes.listItemStyles
  const unselectedListItemTextClasses = classes.unselectedListItemTextStyles
  const selectedListItemTextClasses = classes.selectedListItemTextStyles
  const selectedItemClasses = isSelected ? selectedListItemTextClasses : null
  const submenuItemClasses = isSubMenu
    ? classes.listSubMenuItemTextStyles
    : null
  const listItemTextClasses =
    selectedItemClasses || submenuItemClasses || unselectedListItemTextClasses

  // These two props (to and href) should be mutually exclusive
  const destinationProp = {
    ...((to && !href
      ? {
          to,
        }
      : {}) as Partial<{
      to: Props['to']
    }>),
    ...((href && !to
      ? {
          href,
        }
      : {}) as Partial<{
      href: Props['href']
    }>),
  }

  const menuId =
    getNavMenuId(children).toLowerCase().replace(/ /g, '-') || 'unknown'

  return (
    <ListItem
      button
      classes={{ root: menuItemClasses }}
      component={component}
      data-menuid={`nav-menu-item-${menuId}`}
      data-testid="nav-menu-item"
      disabled={isDisabled}
      email={email}
      onClick={onClick}
      role="button"
      selected={isSelected}
      target={target}
      {...destinationProp}
    >
      <ListItemIcon classes={{ root: classes.leftListItemIconStyles }}>
        {LeftIcon && (
          <LeftIcon
            className={`${isSelected ? classes.iconSelected : ''} ${
              classes.iconStyles
            }`}
            data-testid="nav-menu-item-left-icon"
          />
        )}
      </ListItemIcon>
      <ListItemText
        classes={{ primary: listItemTextClasses }}
        data-testid="nav-menu-item-title"
        primary={children}
      />
      <ListItemIcon classes={{ root: classes.rightListItemIconStyles }}>
        {RightIcon && (
          <RightIcon
            classes={{ root: classes.iconStyles }}
            data-testid="nav-menu-item-right-icon"
          />
        )}
      </ListItemIcon>
    </ListItem>
  )
}

export default NavMenuItem
