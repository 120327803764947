import UAParser from 'ua-parser-js'

const supportedBrowsers = [
  'Mobile Safari',
  'Safari',
  'Chrome Headless',
  'Chrome',
  'Chrome WebView',
  'Chromium',
  'Chromium Headless',
  'Firefox',
  'IE',
  'Edge',
  'OfficeSpaceMobile',
]

const isBrowserSupported = ({
  browser,
  engine,
  os,
}: UAParser.IResult): boolean => {
  if (browser.name != null && supportedBrowsers.includes(browser.name)) {
    return true
  }

  /* This is a fallback because the browser opened by the homescreen bookmark on iOS does not
     return a browser name in the UA string, but we do not want to show the browser warning */
  if (engine.name === 'WebKit' && os.name === 'iOS') {
    return true
  }

  return false
}

const customBrowsers = [
  [/(OfficeSpaceMobile)\/([\w.]+)/i],
  [UAParser.BROWSER.NAME, UAParser.BROWSER.VERSION],
]

const parserData = new UAParser({ browser: customBrowsers }).getResult()

type Browser = {
  name?: string
  type: string
  version?: string
  supported: boolean
}
const browser: Browser = Object.freeze({
  name: parserData.browser.name,
  type: parserData.device.type || '',
  version: parserData.browser.version,
  supported: isBrowserSupported(parserData),
})

export default browser
