import type { CSSProperties } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const defaultSpinnerDiameter = 50
const defaultSpinnerThickness = 4

export type Props = {
  color?: string
  diameter?: number
  style?: CSSProperties
  thickness?: number
}

const CenteredSpinner = ({
  color,
  diameter = defaultSpinnerDiameter,
  style,
  thickness = defaultSpinnerThickness,
}: Props): JSX.Element => (
  <div
    data-testid="content-loading-spinner"
    style={{
      alignItems: 'center',
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      ...style,
    }}
  >
    <CircularProgress
      className="spinner"
      size={diameter}
      style={{
        margin: '0 auto',
        color,
      }}
      thickness={thickness}
    />
  </div>
)

export default CenteredSpinner
