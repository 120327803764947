import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  avatarWrapper: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 1,
    bottom: 1,
    borderRadius: '50%',
    height: 32,
    width: 32,
    background: 'rgba(209, 236, 255, 1)',
    color: 'rgba(10,121, 201, 1)',
  },
  checkedIn: {
    background: '#D3E9D7',
    color: '#35703A',
  },
  inOffice: {
    background: 'rgba(209, 236, 255, 1)',
    color: 'rgba(10,121, 201, 1)',
  },

  icon: {
    width: 20,
    height: 20,
  },
}))
