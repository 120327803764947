// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import type { ComponentPropsWithoutRef } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

type Props = ComponentPropsWithoutRef<typeof SvgIcon>

const SmartPhoneIcon = (props: Props) => (
  <SvgIcon
    {...props}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1665 0.841669L5.83317 0.833336C4.9165 0.833336 4.1665 1.58334 4.1665 2.5V17.5C4.1665 18.4167 4.9165 19.1667 5.83317 19.1667H14.1665C15.0832 19.1667 15.8332 18.4167 15.8332 17.5V2.5C15.8332 1.58334 15.0832 0.841669 14.1665 0.841669ZM14.1665 15.8333H5.83317V4.16667H14.1665V15.8333Z"
      fill="#9E9E9E"
    />
  </SvgIcon>
)

export default SmartPhoneIcon
