// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars,max-len */
import SvgIcon from '@material-ui/core/SvgIcon'

type Props = {
  color?: any
}

export default function LeasesIcon({ color, ...rest }: Props) {
  return (
    <SvgIcon {...rest}>
      <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />
    </SvgIcon>
  )
}
