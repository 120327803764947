// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import type { ReactNode, ReactElement } from 'react'
import { Divider, List } from '@material-ui/core'
import type { SubmenuGroup } from 'common/types/submenu'
import Products, { mainProducts } from 'common/constants/products'
import ProductMenuItems from 'common/components/navmenu/ProductMenuItems'
import isOSSMobile from 'common/lib/ossmobile/isOSSMobile'
import smallOrLess from 'common/selectors/viewport/smallOrLess'
import HelpAndFeedback from '../HelpAndFeedback'
import type { ProductLinks, ProductName } from '../types'

type Props = {
  MobileAppDialog: ReactElement
  productLinks?: ProductLinks
  selectedProduct?: ProductName
  submenus?: SubmenuGroup
  userRole: string
}

const CompactProductLinks = ({
  MobileAppDialog,
  selectedProduct,
  productLinks,
  submenus,
  userRole,
}: Props) => {
  if (!productLinks || Object.keys(productLinks).length === 0) {
    return null
  }

  const isGuest = userRole === 'guest' || userRole === 'delegate_user'

  const mobileAppDialog =
    isOSSMobile || smallOrLess(window) ? <></> : MobileAppDialog

  const renderMainProducts = (): ReactNode =>
    mainProducts.map((product) => {
      const path = productLinks[product]

      if (product === Products.PLANNING_PLATFORM) {
        return null
      }

      return (
        path &&
        /* @ts-expect-error auto-src: non-strict-conversion */
        ProductMenuItems({ submenus })[product]({
          isGuest,
          selected: selectedProduct,
          props: {
            href: path,
          },
        })
      )
    })

  const renderSelectedProduct = (): ReactNode => {
    if (selectedProduct === undefined) {
      return null
    }

    if (mainProducts.includes(selectedProduct)) {
      return null
    }

    const path = productLinks[selectedProduct] || ''

    if (!path) {
      return null
    }

    /* @ts-expect-error auto-src: non-strict-conversion */
    return ProductMenuItems({})[selectedProduct]({
      isGuest,
      selected: selectedProduct,
      props: {
        href: path,
      },
    })
  }

  return (
    <div data-testid="navigation-links">
      <List>
        {renderMainProducts()}
        {renderSelectedProduct()}
      </List>
      <Divider />
      <div>
        <HelpAndFeedback
          MobileAppDialog={mobileAppDialog}
          productLinks={productLinks}
          selectedProduct={selectedProduct}
        />
      </div>
    </div>
  )
}

export default CompactProductLinks
