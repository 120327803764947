/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'

export default (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <g transform="translate(4)">
      <path d="M10,2.5 L15,2.5 L15,21.5 L10,21.5 L10,23.5 L0,21.5943718 L0,2.43048878 L10,0.5 L10,2.5 Z M10,4.75 L10,19.25 L13,19.25 L13,4.75 L10,4.75 Z" />
    </g>
  </SvgIcon>
)
