import { useState } from 'react'
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk'
import defaultFlags from '../launchDarkly/default_flags'

const inTestEnvironment = Boolean(window.oss?.afterInitTestUtils)
const inDevEnvironment = () =>
  process.env.NODE_ENV === 'development' ||
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost'

const useTestEnvFlags = () => ({
  ...defaultFlags,
  ...window.oss?.testUtils?.mockedFlags,
})

const useDevEnvFlags = () => {
  const [devFlags] = useState({
    ...defaultFlags,
    ...window.oss?.mockedFlags,
  })

  return devFlags
}

const getProperHook = () => {
  if (inTestEnvironment) {
    return useTestEnvFlags
  }
  if (inDevEnvironment()) {
    return useDevEnvFlags
  }

  return useLDFlags
}

const useFlags = getProperHook()

export default useFlags
