import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import flatten from 'lodash/flatten'
import type { ApolloClientOptions } from '@apollo/client'
import { ApolloClient, ApolloLink, from, split, empty } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import type { FieldReadFunction } from '@apollo/client/cache/inmemory/policies'
import type { NormalizedCacheObject } from '@apollo/client/cache'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { createUploadLink } from 'apollo-upload-client'
import {
  relayStylePagination,
  getMainDefinition,
} from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import type { Event } from '@bugsnag/js'
import type { DocumentNode } from 'graphql'
import { print } from 'graphql/language/printer'
import type { BugsnagReference } from 'common/createErrorBoundary'
import apolloBatches from 'common/constants/apolloBatches'
import defaultConsumer from 'common/actionCable/channels/consumer'
import { inDevelopmentEnvironment } from 'common/lib/inDevelopmentEnvironment'
import { getCSRFFetchOptions } from './csrf'
import withAuthenticatedFetch from './withAuthenticatedFetch'
import TimeoutLink from './TimeoutLink'

const STATUS = {
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
}

withAuthenticatedFetch()

const httpLinkOptions = {
  fetch,
  uri: '/graphql',
  ...getCSRFFetchOptions(),
}
const httpUploadLink = createUploadLink(httpLinkOptions)
const batchHttpLink = new BatchHttpLink({
  ...httpLinkOptions,
  batchKey: (operation) =>
    operation.getContext().batchName || apolloBatches.default,
  batchMax: 20,
})

export const httpLink = split(
  (operation) =>
    operation.getContext().batchName === null ||
    operation.getContext().hasUpload, // Manually set by each mutation!
  httpUploadLink,
  batchHttpLink,
)
export const webSocketLink = new ActionCableLink({
  cable: defaultConsumer,
})
export const isSubscription = ({
  query,
}: {
  query?: DocumentNode
} = {}): boolean => {
  if (!query) {
    return false
  }

  /* @ts-expect-error auto-src: non-strict-conversion */
  const { kind, operation } = getMainDefinition(query)

  return kind === 'OperationDefinition' && operation === 'subscription'
}

const logout = () => {
  /* @ts-expect-error Typescript upgrade to 4.7 */
  window.location.reload(true)
}

export const requestsCountMiddleware = new ApolloLink((operation, forward) => {
  if (window.oss?.testUtils && !isSubscription(operation)) {
    window.oss.testUtils.pendingRequestsCount += 1
  }

  return forward(operation).map((response) => {
    if (window.oss?.testUtils && !isSubscription(operation)) {
      window.oss.testUtils.pendingRequestsCount -= 1
    }

    return response
  })
})
export const createLogoutLink = (
  bugsnag: BugsnagReference | null | undefined = undefined,
): ApolloLink =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    const bugsnagClient = bugsnag?.client

    if (bugsnagClient && networkError) {
      bugsnagClient.notify(networkError, (event: Event) => {
        if (networkError.name === 'ServerParseError') {
          event.addMetadata('ApolloOperation', {
            operationName: operation.operationName,
            query: print(operation.query),
            variables: JSON.stringify(operation.variables),
          })
          event.addMetadata('ServerParseData', {
            /* @ts-expect-error auto-src: non-strict-conversion */
            bodyText: networkError.bodyText,
            /* @ts-expect-error auto-src: non-strict-conversion */
            response: networkError.response,
            /* @ts-expect-error auto-src: non-strict-conversion */
            statusCode: networkError.statusCode,
          })
        }
      })
    }

    if (bugsnagClient && graphQLErrors) {
      const ApolloOperation = {
        operationName: operation.operationName,
        query: print(operation.query),
        variables: JSON.stringify(operation.variables),
      }

      graphQLErrors.forEach((errorData) => {
        const error = new Error(errorData.message)

        error.name = 'GraphQLError'
        bugsnagClient.notify(error, (event: Event) => {
          event.addMetadata('ApolloOperation', ApolloOperation)
          event.addMetadata('GraphQLErrorData', {
            extensions: errorData.extensions,
            locations: errorData.locations,
            path: errorData.path,
          })
        })
      })
    }

    /* @ts-expect-error auto-src: non-strict-conversion */
    const statusCode = networkError && networkError.statusCode

    if (
      statusCode === STATUS.UNAUTHORIZED ||
      statusCode === STATUS.UNPROCESSABLE_ENTITY
    ) {
      logout()

      return forward(operation)
    }
  })

export const createBreadcrumbLink = (
  bugsnag: BugsnagReference | null | undefined,
): ApolloLink =>
  new ApolloLink((operation, forward) => {
    const bugsnagClient = bugsnag?.client

    if (bugsnagClient) {
      bugsnagClient.leaveBreadcrumb(
        `Apollo Operation: ${operation.operationName}`,
        {
          query: print(operation.query),
          variables: JSON.stringify(operation.variables),
        },
      )
    }

    return forward(operation)
  })
const specialKeys = {
  directories: 'Directory',
  utilities: 'Utility',
}

const getTypename = (fieldName: string) =>
  /* @ts-expect-error auto-src: strict-conversion */
  specialKeys[fieldName] ||
  `${fieldName[0].toUpperCase()}${fieldName.substr(1).slice(0, -1)}`

const cacheRedirect: FieldReadFunction = (
  existingData,
  { args, fieldName, toReference, canRead },
) => {
  const __typename = getTypename(fieldName)

  const ids = args?.ids ? flatten([args.ids]) : []
  const references = (
    existingData ||
    ids.map((id) =>
      toReference({
        __typename,
        id,
      }),
    )
  ).filter(canRead)

  // Dangled references on existing data might cause inconsistencies, it's best to avoid
  if (existingData && existingData.length !== references.length) {
    return undefined
  }

  // To clarify: it's only possible to get cached data from queries not using IDs if they've been fetched before
  if (!ids.length && !existingData) {
    return undefined
  }

  // No cached query and no references means it doesn't exist
  if (ids.length && !existingData && !references.length) {
    return undefined
  }

  return references
}

export const fieldsWithCacheRedirect = {
  directories: cacheRedirect,
  employees: cacheRedirect,
  floors: cacheRedirect,
  moves: cacheRedirect,
  neighborhoods: cacheRedirect,
  pins: cacheRedirect,
  rooms: cacheRedirect,
  scenarios: cacheRedirect,
  seats: cacheRedirect,
  sites: cacheRedirect,
  utilities: cacheRedirect,
}
const singletonType = {
  keyFields: [],
}
const volatileType = {
  merge: false,
}

export const makeCache = (): InMemoryCache =>
  new InMemoryCache({
    typePolicies: {
      AccessControlLogs: singletonType,
      ActiveDirectorySettings: singletonType,
      BrivoIntegrationSettings: singletonType,
      DeskBookingSettings: singletonType,
      EmployeeCsv: singletonType,
      EmployeeFieldSetting: {
        keyFields: ['fieldName'],
      },
      FieldExposure: singletonType,
      KisiIntegrationSettings: singletonType,
      NamelyImageSyncSettings: singletonType,
      OfficeSpaceSamlAuthenticationHelpText: singletonType,
      OfficeSpaceSamlAuthenticationSettings: singletonType,
      OpenpathIntegrationSettings: singletonType,
      PlaiCredential: singletonType,
      PlaiIntegrationStatus: singletonType,
      RoomBookingSettings: singletonType,
      RoomDisplaySettings: { keyFields: false },
      SamlAuthenticationHelpText: singletonType,
      SamlAuthenticationSettings: singletonType,
      ScimConnectorSettings: singletonType,
      UserRolesSettings: singletonType,
      Seat: {
        fields: {
          activeAndUpcomingBookings: volatileType,
          /* @ts-expect-error auto-src: non-strict-conversion */
          bookingAvailability: singletonType,
        },
      },
      Room: {
        fields: {
          /* @ts-expect-error auto-src: non-strict-conversion */
          bookingAvailabilityStatus: singletonType,
        },
      },
      Query: {
        fields: {
          ...fieldsWithCacheRedirect,
          bookableRoomSearch: relayStylePagination(),
          whoIsInRosterItems: relayStylePagination(),
        },
      },
    },
  })
export const cache = makeCache()
export const requestsBlockerLink = (
  requestsAllowedLink: ApolloLink,
): ApolloLink =>
  split(
    () => Boolean(window.oss?.testUtils?.requestsBlocked),
    empty(),
    requestsAllowedLink,
  )

const timeoutLink = (timeout?: number): ApolloLink =>
  split(
    (operation) => Boolean(timeout) && !isSubscription(operation),
    new TimeoutLink(timeout as number),
  )

type Options = ApolloClientOptions<
  Omit<NormalizedCacheObject, 'cache' | 'link'>
>

export default (
  options?: Options | null,
  bugsnag?: BugsnagReference | null,
  timeout?: number,
): ApolloClient<NormalizedCacheObject> => {
  const link = from([
    requestsCountMiddleware,
    createBreadcrumbLink(bugsnag),
    createLogoutLink(bugsnag),
    timeoutLink(timeout),
    requestsBlockerLink(split(isSubscription, webSocketLink, httpLink)),
  ])

  return new ApolloClient({
    ...options,
    connectToDevTools: inDevelopmentEnvironment,
    cache,
    link,
  })
}
