import { makeStyles } from '@material-ui/styles'

export default makeStyles({
  buttonWrapper: {
    flexShrink: 0,
    width: 53,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: 2,
    width: 42,
    height: 42,
  },
})
