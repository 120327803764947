import CheckIcon from '@material-ui/icons/Check'
import InOfficeIcon from 'common/components/icons/InOffice'
import useFlags from 'common/hooks/useFlags'
import Definitions from '../../constants/definitions'
import StyleConstants from '../styles/definitions'
import Avatar from './Avatar'
import type { Props as AvatarProps } from './Avatar'
import useStyles from './useStyles'

type Props = Pick<AvatarProps, 'containerStyle' | 'innerWrapperOverrides'> & {
  fallbackPhotoSize: 'large' | 'thumb'
  hasCheckedIn?: boolean
  isPresent?: boolean
  photo?: AvatarProps['src']
  size?: AvatarProps['size']
}

const EmployeeAvatar = ({
  containerStyle,
  fallbackPhotoSize,
  hasCheckedIn,
  innerWrapperOverrides,
  isPresent,
  photo,
  size = StyleConstants.employeePhoto.default.size,
}: Props): JSX.Element => {
  const classes = useStyles()

  const { 'visual_directory.presence_ui.enable': enablePresenceUI } = useFlags()

  return (
    <div className={classes.avatarWrapper} data-testid="employee-avatar">
      <Avatar
        containerStyle={containerStyle}
        fallbackSrc={Definitions.missingEmployeeImage(fallbackPhotoSize)}
        innerWrapperOverrides={innerWrapperOverrides}
        size={size}
        src={photo}
      />

      {enablePresenceUI && isPresent ? (
        <div
          className={`${classes.badge} ${classes.inOffice}`}
          data-testid="in-office-icon"
        >
          <InOfficeIcon className={classes.icon} />
        </div>
      ) : (
        hasCheckedIn && (
          <div
            className={`${classes.badge} ${classes.checkedIn}`}
            data-testid="checked-in-icon"
          >
            <CheckIcon className={classes.icon} />
          </div>
        )
      )}
    </div>
  )
}

export default EmployeeAvatar
