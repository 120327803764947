// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react'
import type { ComponentType } from 'react'
import { List } from '@material-ui/core'
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import isString from 'lodash/isString'
import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import navItemIcons from 'nav_menu/icons'
import useRoomScreensConfiguration from 'admin_console/hooks/useRoomScreensConfiguration'

type Label = string
type Link = string
type SubLinks = Record<Label, Link>
type NavItemsProps = {
  name: string
  /* @ts-expect-error auto-src: non-strict-conversion */
  Icon: ComponentType<SvgIcon>
  menuItems: SubLinks
  isOpen?: boolean
}

const NavItems = ({ name, Icon, menuItems, isOpen = false }: NavItemsProps) => {
  const [open, setOpen] = useState<boolean>(isOpen)

  return (
    <List component="div" disablePadding>
      <NavMenuItem
        LeftIcon={Icon}
        onClick={() => setOpen(!open)}
        RightIcon={open ? ExpandLessIcon : ExpandMoreIcon}
      >
        {name}
      </NavMenuItem>
      {open &&
        (Object.entries(menuItems) as ReadonlyArray<[Label, Link]>).map(
          ([label, link]) => (
            <NavMenuItem key={link} component="a" href={link} isSubMenu>
              {label}
            </NavMenuItem>
          ),
        )}
    </List>
  )
}

type Props = {
  navItemLinks: Record<Label, SubLinks | Link>
}

const NavItemsList = ({ navItemLinks }: Props) => {
  const { configured: showRoomDisplays } = useRoomScreensConfiguration()

  /* @ts-expect-error auto-src: strict-conversion */
  const isDisabled = (label) => label === 'Room Displays' && !showRoomDisplays

  return (
    <List component="div" data-testid="app-nav">
      {(
        Object.entries(navItemLinks) as ReadonlyArray<[Label, SubLinks | Link]>
      ).map(([label, links]) => {
        if (isDisabled(label)) {
          return null
        }

        if (isString(links)) {
          return (
            <NavMenuItem
              key={label}
              component="a"
              href={links as string}
              /* @ts-expect-error auto-src: strict-conversion */
              LeftIcon={navItemIcons[label]}
            >
              {label}
            </NavMenuItem>
          )
        }

        return (
          <NavItems
            key={label}
            /* @ts-expect-error auto-src: strict-conversion */
            Icon={navItemIcons[label]}
            isOpen={Boolean(
              (Object.values(links as SubLinks) as ReadonlyArray<Link>).find(
                (itemLink: Link) =>
                  itemLink.toLowerCase().split('#')[0] ===
                  window.location.pathname.toLowerCase(),
              ),
            )}
            menuItems={links}
            name={label}
          />
        )
      })}
    </List>
  )
}

export default NavItemsList
