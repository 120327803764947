import { useMemo, useState } from 'react'
import type { ReactNode } from 'react'
import isEmpty from 'lodash/isEmpty'
import User from 'common/records/user'
import type { SubmenuGroup } from 'common/types/submenu'
import AppMenuFooter from 'common/components/navmenu/AppMenuFooter'
import ConditionalAvatar from 'common/components/navmenu/ConditionalAvatar'
import MobileAppDialog from 'common/components/navmenu/MobileAppDialog'
import mediumOrGreater from 'common/selectors/viewport/mediumOrGreater'
import useWindowResize from 'common/hooks/useWindowResize'
import {
  CompactProductLinks,
  ExtendedProductLinks,
} from 'common/components/navmenu/ProductLinks'
import UserSummary from 'common/components/navmenu/UserSummary'
import isMobileSafari from 'common/lib/isMobileSafari'
import NavItemsList from 'common/components/navmenu/NavItemsList'

import OpenableNavMenu from './OpenableNavMenu'

type Label = string
type Link = string
type SubLinks = Record<Label, Link>

type Props = {
  navItemLinks: SubLinks
  productLinks: SubLinks
  submenus: SubmenuGroup
  userRole: string
  selectedProduct: string
  alwaysShowNavItemLinks: boolean
}

type ComposedProps = Props & {
  anonymous: boolean
  customIOSAppLink?: string
  manageAccountProductLink: string
  userId: string
  email: string
  fullName: string
  isIOSAppEnabled: boolean
  photo: string
  shouldShowAvatar: boolean
  version: string
  navItems: ReactNode
  productLinksForBreakpoint: ReactNode
}

const NavMenu = ({
  navItemLinks,
  productLinks,
  submenus,
  userRole,
  selectedProduct,
  alwaysShowNavItemLinks,
  anonymous,
  manageAccountProductLink,
  userId,
  email,
  fullName,
  customIOSAppLink,
  isIOSAppEnabled,
  photo,
  shouldShowAvatar,
  version,
}: ComposedProps): JSX.Element => {
  const [isMobileAppDialogOpen, setMobileAppDialogOpen] = useState(false)
  const extendedWindow = useWindowResize<boolean>(mediumOrGreater)

  const navItemsForBreakpoint = useMemo(() => {
    if (!alwaysShowNavItemLinks && (extendedWindow || isEmpty(navItemLinks))) {
      return null
    }

    return <NavItemsList navItemLinks={{ ...navItemLinks, ...submenus }} />
  }, [alwaysShowNavItemLinks, extendedWindow, navItemLinks, submenus])

  const productLinksForBreakpoint = useMemo(() => {
    const ProductLinks =
      extendedWindow && !isMobileSafari()
        ? ExtendedProductLinks
        : CompactProductLinks

    const mobileAppDialog = (
      <MobileAppDialog
        customIOSAppLink={customIOSAppLink}
        isIOSAppEnabled={isIOSAppEnabled}
        isOpen={isMobileAppDialogOpen}
        isUserAnonymous={anonymous}
        setIsOpen={setMobileAppDialogOpen}
      />
    )

    return (
      <ProductLinks
        MobileAppDialog={mobileAppDialog}
        productLinks={productLinks}
        selectedProduct={selectedProduct}
        submenus={submenus}
        userRole={userRole}
      />
    )
  }, [
    anonymous,
    customIOSAppLink,
    extendedWindow,
    isIOSAppEnabled,
    isMobileAppDialogOpen,
    setMobileAppDialogOpen,
    productLinks,
    selectedProduct,
    submenus,
    userRole,
  ])

  return (
    <OpenableNavMenu
      footer={
        <AppMenuFooter
          currentUser={User({
            anonymous,
          })}
          customIOSAppLink={customIOSAppLink}
          isIOSAppEnabled={isIOSAppEnabled}
          version={version}
        />
      }
      navItems={navItemsForBreakpoint}
      productLinks={productLinksForBreakpoint}
      userSummary={
        <UserSummary
          anonymous={anonymous}
          avatar={
            <ConditionalAvatar
              photo={photo}
              shouldShowAvatar={shouldShowAvatar}
            />
          }
          email={email}
          fullName={fullName}
          manageAccountProductLink={manageAccountProductLink}
          userId={userId}
        />
      }
    />
  )
}

export default NavMenu
