import { lazy, Suspense } from 'react'
import type { ReactNode } from 'react'
import type { Submenu } from 'common/types/submenu'
import SimpleProductItem from './SimpleProductItem'
import SubMenuProductItem from './SubMenuProductItem'

import {
  isVisualDirectory,
  shouldHideVDSubmenu,
  isProductSelected,
} from '../lib'

type Link = {
  href?: string
  target?: string
}

type Props = {
  Icon: ReactNode
  product: string
  submenu?: Submenu
  link: Link
  isGuest?: boolean
  selected?: string
}

const VisualDirectoryProductItem = lazy(
  () =>
    import(
      /* webpackChunkName: "vd_product_submenu_item" */
      './VisualDirectoryProductItem'
    ),
)

const ProductItems = ({
  Icon,
  product,
  submenu,
  link,
  isGuest,
  selected,
}: Props): JSX.Element => {
  const { href } = link
  const buildSimpleProductItem = () => (
    <SimpleProductItem
      key={`${product}-item-single-item`}
      Icon={Icon}
      isProductRegistered={isVisualDirectory(product)}
      isProductSelected={isProductSelected(product, selected, href)}
      link={link}
      product={product}
    />
  )

  if (isVisualDirectory(product) && !shouldHideVDSubmenu(product, selected)) {
    return (
      <Suspense
        key={`${product}-item-single-item`}
        fallback={buildSimpleProductItem()}
      >
        <VisualDirectoryProductItem
          Icon={Icon}
          isGuest={isGuest}
          isOpen
          name={product}
        />
      </Suspense>
    )
  }

  if (isVisualDirectory(product) && shouldHideVDSubmenu(product, selected)) {
    return buildSimpleProductItem()
  }

  if (submenu) {
    return (
      <SubMenuProductItem
        key={`${product}-item-with-submenu`}
        Icon={Icon}
        name={product}
        submenu={submenu}
      />
    )
  }

  return buildSimpleProductItem()
}

export default ProductItems
