// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { ReactElement } from 'react'
import { List, Divider } from '@material-ui/core'
import result from 'lodash/result'
import type { SubmenuGroup } from 'common/types/submenu'
import Products, { mainProducts } from 'common/constants/products'
import ProductMenuItems from 'common/components/navmenu/ProductMenuItems'
import bustIframe from 'common/lib/nav_menu/bustIframe'
import isOSSMobile from 'common/lib/ossmobile/isOSSMobile'
import smallOrLess from 'common/selectors/viewport/smallOrLess'
import HelpAndFeedback from '../HelpAndFeedback'
import type { ProductLinks, ProductName } from '../types'

type Props = {
  MobileAppDialog: ReactElement
  productLinks?: ProductLinks
  selectedProduct?: ProductName
  submenus?: SubmenuGroup
  userRole: string
}

const ExtendedProductLinks = ({
  MobileAppDialog,
  selectedProduct,
  productLinks,
  submenus,
  userRole,
}: Props) => {
  if (!productLinks || Object.keys(productLinks).length === 0) {
    return null
  }
  const isGuest = userRole === 'guest' || userRole === 'delegate_user'
  const mobileAppDialog =
    isOSSMobile || smallOrLess(window) ? <></> : MobileAppDialog

  return (
    <div>
      <List>
        {mainProducts.map((product) => {
          const path = productLinks[product]

          if (!path) {
            return null
          }

          if (product === Products.PLANNING_PLATFORM) {
            return null
          }

          /* @ts-expect-error auto-src: non-strict-conversion */
          return ProductMenuItems({ submenus })[product]({
            isGuest,
            selected: selectedProduct,
            props: {
              href: path,
              target:
                result(bustIframe, product) && !isOSSMobile
                  ? '_blank'
                  : '_self',
            },
          })
        })}
      </List>
      <Divider />
      <div>
        <HelpAndFeedback
          MobileAppDialog={mobileAppDialog}
          productLinks={productLinks}
          selectedProduct={selectedProduct}
        />
      </div>
    </div>
  )
}

export default ExtendedProductLinks
