import ProductConstants from 'common/constants/products'
import type { SubmenuGroup } from 'common/types/submenu'
import { useProductLinksQuery } from 'generated/graphql'
import type { Path, ProductName, ProductLinks } from './types'

type Result = {
  productLinks: ProductLinks | undefined
  submenus: SubmenuGroup | undefined
  manageAccountProductLink: Path
}

const useProductLinks = (selectedProduct: ProductName): Result => {
  const { data } = useProductLinksQuery({
    variables: { product: selectedProduct },
  })
  const productLinks = data?.crossProductLinks as ProductLinks | undefined
  const submenus = data?.submenus as SubmenuGroup | undefined
  const manageAccountProductLink =
    (productLinks && productLinks[ProductConstants.MANAGE_ACCOUNT]) || ''

  return {
    productLinks,
    submenus,
    manageAccountProductLink,
  }
}

export default useProductLinks
