import { useTranslation } from 'react-i18next'

import useFlags from 'common/hooks/useFlags'
import VisitorManagementIcon from 'common/components/icons/VisitorManagementIcon'
import NavMenuItem from '../NavMenuItem'

type Props = {
  link?: {
    href?: string
    target?: string
  }
}

const VisitorManagementMenuItem = ({
  link: { href = 'https://app.greetly.com', target = '_blank' } = {},
}: Props): JSX.Element => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.home_menu',
  })

  const { 'vms_menu_item.enable': isVmsMenuItemEnabled } = useFlags()

  return (
    <NavMenuItem
      component="a"
      href={href}
      isDisabled={!isVmsMenuItemEnabled}
      LeftIcon={VisitorManagementIcon}
      target={target}
    >
      {t('visitor_management')}
    </NavMenuItem>
  )
}

export default VisitorManagementMenuItem
