import { useLayoutEffect, useState } from 'react'
import type { CSSProperties, SyntheticEvent } from 'react'
import AvatarStyles from './styles'

export type Props = {
  containerStyle?: CSSProperties
  fallbackSrc: string
  innerWrapperOverrides?: CSSProperties
  size: number
  src?: string | null
}

const Avatar = ({
  containerStyle,
  fallbackSrc,
  innerWrapperOverrides,
  size,
  src,
}: Props): JSX.Element => {
  const [imgError, setImgError] = useState<boolean>(false)
  const [internalSrc, setInternalSrc] = useState<string>(src || fallbackSrc)
  const [imageStyleOverrides, setImageStyleOverrides] = useState<{
    [key: string]: string | number | undefined
  }>({
    opacity: 0,
    visibility: 'hidden',
  })
  const [internalInnerWrapperOverrides, setInternalInnerWrapperOverrides] =
    useState({
      opacity: 0,
    })

  useLayoutEffect(() => {
    setInternalSrc(src || fallbackSrc)
    setImgError(false)
    setImageStyleOverrides({ opacity: 0 })
  }, [fallbackSrc, src])

  const handleError = () => {
    setImgError(true)
  }

  // Cannot do this by using style={{ objectFit: 'cover' }} on the Material UI Avatar
  // because that CSS does not crop tall images the way Shamim wants (see PR #5270).
  const handleImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
    const image = event.currentTarget
    const containerHeight = size
    const containerWidth = size
    const scaleFactor =
      image.height > image.width
        ? image.width / containerHeight
        : image.height / containerWidth
    const constrainingDimension =
      image.height > image.width ? 'width' : 'height'
    const scaledWidth = image.width / scaleFactor
    const scaledHeight = image.height / scaleFactor

    setImageStyleOverrides({
      [constrainingDimension]: containerWidth,
      marginLeft: -Math.max((scaledWidth - containerWidth) / 2, 0) || undefined,
      marginTop:
        -Math.max(
          // eslint-disable-next-line no-magic-numbers
          (scaledHeight - containerHeight) / 3,
          0,
        ) || undefined,
      visibility: '',
    })
    setInternalInnerWrapperOverrides({ opacity: 1 })
  }

  return (
    <div
      data-testid="avatar"
      style={{
        ...AvatarStyles.container,
        height: size,
        width: size,
        minWidth: size,
        ...containerStyle,
      }}
    >
      <div
        data-testid="avatar-inner-wrapper"
        style={{
          ...AvatarStyles.innerWrapper,
          height: size,
          width: size,
          ...innerWrapperOverrides,
          ...internalInnerWrapperOverrides,
        }}
      >
        <img
          alt=""
          data-testid="avatar-image"
          loading="lazy"
          onError={handleError}
          onLoad={handleImageLoad}
          src={imgError ? fallbackSrc : internalSrc}
          style={{ ...AvatarStyles.image, ...imageStyleOverrides }}
        />
      </div>
    </div>
  )
}

export default Avatar
