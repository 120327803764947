import StyleConstants from './definitions'

const navbarIconPaddingY =
  (StyleConstants.navbar.height - StyleConstants.icon.iconSize) / 2

export const navbarIcon = {
  style: {
    height: StyleConstants.navbar.height,
    padding: navbarIconPaddingY,
    width: StyleConstants.navbar.height,
  },
  iconStyle: {
    color: StyleConstants.navbar.standard.iconColor,
  },
  navMenu: {
    icon: {
      style: {
        height: StyleConstants.navbar.height,
        padding: `${navbarIconPaddingY}px 15px`,
        width: 'auto',
      },
    },
  },
} as const

export const navMenuStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    width: '280px',
    overflowX: 'hidden',
  },
  user: {
    container: {
      overflow: 'hidden',
      padding: '25px 26px 15px 24px',
    },
    avatarWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 1,
    },
    ossLogo: {
      marginLeft: 'auto',
    },
    infoSummaryContainer: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '12px',
      fontFamily: StyleConstants.font.family,
    },
    text: {
      color: StyleConstants.font.color.minorHeading,
      fontSize: StyleConstants.font.size.standard,
      lineHeight: '18px',
    },
    name: {
      fontWeight: 'bold',
      margin: 0,
    },
    editProfile: {
      icon: {
        height: '24px',
        width: '24px',
        lineHeight: '24px',
        padding: '0px',
        top: '0px',
        right: '0px',
        fill: '#212121',
      },
      label: {
        opacity: '1',
        top: '0',
        color: StyleConstants.icon.iconColor,
        fontSize: '13px',
        paddingLeft: '0px',
        paddingRight: '29px',
        height: '18px',
        lineHeight: '18px',
        marginBottom: '4px',
        marginTop: '2px',
      },
      list: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      dropDownMenu: {
        height: '24px',
        fontFamily: StyleConstants.font.family,
      },
      menuItem: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '13px',
        justifyContent: 'center',
        lineHeight: '18px',
        minHeight: '32px',
        fontFamily: StyleConstants.font.family,
      },
      menuItemTextWrap: {
        display: 'inline-block',
        maxWidth: '212px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      disabledMenuItem: {
        display: 'none',
      },
      menuItemInnerDiv: {
        color: StyleConstants.navbar.highlighted.color,
        textAlign: 'left',
        lineHeight: '34px',
      },
      underline: {
        display: 'none',
      },
    },
  },
  superscript: {
    fontSize: '61.54%',
    lineHeight: '61.54%',
    paddingLeft: '2px',
    position: 'relative',
    verticalAlign: 'super',
    marginBottom: 6,
  },
  linksList: {
    reportsRightIcon: {
      right: '0px',
      left: '111px',
      height: '18px',
      width: '40px',
    },
    managerRightIconClassic: {
      right: '0px',
      left: '158px',
      height: '18px',
      width: '52px',
      color: '#575757',
      borderRadius: '2px',
      backgroundColor: '#E0E0E0',
      fontFamily: StyleConstants.font.family,
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '18px',
      textAlign: 'center',
    },
    reportsRightIconClassic: {
      right: '0px',
      left: '111px',
      height: '18px',
      width: '52px',
      color: '#575757',
      borderRadius: '2px',
      backgroundColor: '#E0E0E0',
      fontFamily: StyleConstants.font.family,
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '18px',
      textAlign: 'center',
    },
    managerRightIconBeta: {
      right: '0px',
      left: '158px',
      height: '18px',
      width: '40px',
      color: '#42A5F5',
      borderRadius: '2px',
      backgroundColor: '#D9EDFC',
      fontFamily: StyleConstants.font.family,
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '18px',
      textAlign: 'center',
    },
    reportsRightIconBeta: {
      right: '0px',
      left: '111px',
      height: '18px',
      width: '40px',
      color: '#42A5F5',
      borderRadius: '2px',
      backgroundColor: '#D9EDFC',
      fontFamily: StyleConstants.font.family,
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '18px',
      textAlign: 'center',
    },
    listItem: {
      innerDivStyle: {
        padding: '13px 16px 13px 68px',
      },
      leftIcon: {
        boxSizing: 'content-box',
        color: StyleConstants.icon.iconColor,
        fill: StyleConstants.icon.iconColor,
        margin: '8px',
        paddingLeft: '8px',
      },
      requestManagerIcon: {
        paddingLeft: '11px',
        width: '20px',
      },
      style: {
        color: StyleConstants.font.color.majorHeading,
        fontSize: StyleConstants.font.size.standard,
        fontWeight: StyleConstants.font.weight.semibold,
        lineHeight: '14px',
        fontFamily: StyleConstants.font.family,
      },
      selectedItem: {
        backgroundColor:
          StyleConstants.colorPalette.lists.selectedItemBackground,
        color: StyleConstants.navbar.highlighted.color,
      },
      disabledItem: {
        color: StyleConstants.colorPalette.disabled,
      },
      disabledIcon: {
        color: StyleConstants.colorPalette.disabled,
        fill: StyleConstants.colorPalette.disabled,
      },
    },
  },
  footer: {
    container: {
      overflow: 'hidden',
      padding: '9px 25px 9px 24px',
      verticalAlign: 'text-top',
    },
    versionLabel: {
      color: StyleConstants.labels.readonly.textColor,
      display: 'inline-block',
      float: 'right',
      fontSize: '12px',
      lineHeight: '32px',
      paddingTop: '4px',
      fontFamily: StyleConstants.font.family,
    },
    iOSApp: {
      buttonContainer: {
        height: '100%',
        lineHeight: null,
        minWidth: null,
        left: '-7px',
        padding: '6px 7px',
        position: 'relative',
      },
      buttonLabel: {
        color: StyleConstants.navbar.highlighted.color,
        fontSize: '12px',
        fontWeight: '600',
        height: '17px',
        lineHeight: '17px',
        textTransform: 'unset',
        padding: '0px',
      },
      qrCodeContainer: {
        position: 'relative',
      },
      qrCode: {
        margin: '0 auto',
        display: 'block',
      },
    },
  },
} as const

export const FooterHamburgerMenuStyle = {
  container: {
    width: '100%',
    height: '56px',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    width: '100%',
    height: '16px',
    margin: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '50%',
    height: '16px',
  },
  version: {
    fontWeight: 600,
    fontSize: '12px',
    width: '50%',
    color: '#757575',
    textAlign: 'right',
    display: 'inline-block',
    lineHeight: '16px',
    fontFamily: 'Open Sans',
  },
} as const
