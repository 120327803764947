import { useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { NavMenuState } from 'common/reducers/navMenu'

type RootState = {
  navMenu: NavMenuState
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default useAppSelector
