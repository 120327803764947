import { utils } from 'pixi.js-legacy'
import isSystemVirtualized from './isSystemVirtualized'

const isWebGLSupported = () => {
  if (
    /* @ts-expect-error auto-src: non-strict-conversion */
    window.disableWebGL ||
    /* @ts-expect-error auto-src: non-strict-conversion */
    (window.disableWebGLWhenVirtualized && isSystemVirtualized())
  ) {
    return false
  }

  return utils.isWebGLSupported()
}

export default isWebGLSupported()
