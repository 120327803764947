import { makeStyles } from '@material-ui/styles'

export const useDialogStyles = makeStyles({
  paperScrollPaper: {
    maxHeight: 'calc(100% - 8px)',
    width: '780px',
  },
})

export const useActionsStyles = makeStyles({
  root: {
    padding: '16px 8px 8px 24px',
  },
})

export const useTitleStyles = makeStyles({
  root: {
    paddingBottom: '4px',
    '& .MuiTypography-h6': {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '28px',
      color: '#212121',
      paddingTop: '8px',
    },
  },
})

export const useOuterContainerStyles = makeStyles({
  root: {
    display: 'flex',
    padding: '0px',
  },
})

export const useMobileContainerStyles = makeStyles({
  outerContainer: {
    padding: '20px 0px 0px 24px',
  },
  blueContainer: {
    display: 'flex',
    backgroundColor: '#BEDFEC40',
    height: '88px',
    width: '439px',
    padding: '8px 24px 8px 0px !important',
    marginBottom: '24px',
  },
  badges: {
    paddingRight: '16px',
    height: '36px',
  },
  qrCode: {
    padding: '8px 32px 0px 0px',
    minWidth: '250px',
  },
  boldedText: {
    fontWeight: 600,
  },
})

export const useDownloadInstructionStyles = makeStyles({
  root: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#212121',
  },
})

export const useInstructionStyles = makeStyles({
  root: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#000000',
    '& > span': {
      fontWeight: 600,
    },
  },
})
