import Skeleton from '@material-ui/lab/Skeleton'
import OssLogoIconBlue from 'common/components/icons/OssLogoIconBlue'
import { navMenuStyle } from 'common/components/styles/navmenu'

const UserSummaryLoadingState = (): JSX.Element => {
  const userStyles = navMenuStyle.user

  return (
    <div style={{ ...userStyles.container, ...userStyles.text }}>
      <div style={userStyles.avatarWrapper}>
        <Skeleton animation="wave" height={50} variant="circle" width={50} />
        <OssLogoIconBlue style={userStyles.ossLogo} />
      </div>
      <Skeleton
        animation="wave"
        height={12}
        style={{
          borderRadius: '20px',
          transform: 'none',
          margin: '10px 0 6px 0',
        }}
        variant="text"
        width="140px"
      />
      <Skeleton
        animation="wave"
        height={12}
        style={{ borderRadius: '20px', transform: 'none', margin: '6px 0' }}
        variant="text"
        width="140px"
      />
    </div>
  )
}

export default UserSummaryLoadingState
